import React from 'react';

import { ReactComponent as RoadSign } from 'images/road-sign.svg';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';

import styles from './NotEligible.module.scss';

const NotEligible = ({ handleNext, navigationInfo }: FlowComponentType) => (
  <div>
    <FormNavigation {...navigationInfo} />
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <RoadSign />
      </div>
      <div className={styles.title}>We’re unable to help you with student loans this time.</div>
      <div className={styles.description}>Continue along your path to financial freedom</div>
      <Button type={ButtonType.Primary} onClick={handleNext}>
        Continue
      </Button>
    </div>
  </div>
);

export default NotEligible;
