import Api from './Api';

export interface PaycheckSwitchOfferData {
  installmentPayments: boolean;
  interestRate: number;
  creditLimit: number;
}

export interface PaycheckSwitchApi {
  getPaycheckSwitchOffer(applicationId: string): Promise<PaycheckSwitchOfferData>;
}

export default class PaycheckSwitchRestApi extends Api implements PaycheckSwitchApi {
  async getPaycheckSwitchOffer(applicationId: string): Promise<PaycheckSwitchOfferData> {
    return this.fetch<PaycheckSwitchOfferData>(`/paycheckSwitch/${applicationId}`);
  }
}
