import React, { useEffect, useState } from 'react';
import FormNavigation from 'components/FormNavigation';
import RadioButtonList from 'components/Common/RadioButtonList/RadioButtonList';
import { FlowComponentType } from 'routes/FlowRouter';
import Button from 'components/Button';
import { StudentLoanEmployerResult } from 'enums/StudentLoanForgivenessFlowResults';
import { ConversionType, trackConversion } from 'utils/analytics';
import StudentLoanLoaderWrapper from 'components/StudentLoanForgiveness/LoaderWrapper';

import QuestionsWrapper from './Wrapper/QuestionsWrapper';

import styles from './Questions.module.scss';

const options = [
  { id: StudentLoanEmployerResult.NonProfit, label: 'My employer is a non-profit' },
  { id: StudentLoanEmployerResult.ForProfit, label: 'My employer is a for profit company' },
  { id: StudentLoanEmployerResult.NotSure, label: "I'm not sure" },
];

const EmployerType = ({ flags, navigationInfo, handleNext }: FlowComponentType) => {
  const [selected, setSelected] = useState<StudentLoanEmployerResult | null>(null);

  const handleClick = () => {
    analytics.track('Student Loan Employer Type Selected', { selected });
    handleNext(selected!);
  };

  useEffect(() => {
    trackConversion(ConversionType.StudentLoanOfferAvailable);
  }, []);

  return (
    <StudentLoanLoaderWrapper flags={flags}>
      <>
        <FormNavigation {...navigationInfo} />
        <QuestionsWrapper>
          <>
            <div className={styles.questionWrapper}>
              <RadioButtonList selected={selected} setSelected={setSelected} options={options} />
              <p className={styles.hint}>Most hospitals and universities are non-profits.</p>
            </div>

            <Button disabled={Boolean(!selected)} onClick={handleClick} className={styles.button}>
              Next
            </Button>
          </>
        </QuestionsWrapper>
      </>
    </StudentLoanLoaderWrapper>
  );
};

export default EmployerType;
