import { NavigateOptions } from 'react-router-dom';

import EmployerType from 'components/StudentLoanForgiveness/Questions/EmployerType';
import NonProfitHireDate from 'components/StudentLoanForgiveness/Questions/NonProfitHireDate';
import ForgivenessAwareness from 'components/StudentLoanForgiveness/Questions/ForgivenessAwareness';
import NotEnrolled from 'components/StudentLoanForgiveness/Questions/NotEnrolled';
import StudentLoanSavings from 'components/StudentLoanForgiveness/StudentLoanSavings/StudentLoanSavings';
import BookAdvisor from 'components/StudentLoanForgiveness/BookAdvisor/BookAdvisor';
import BookAppointment from 'components/StudentLoanForgiveness/BookAppointment/BookAppointment';

import { RoutePath } from 'enums/Routes';
import {
  StudentLoanEmployerResult,
  StudentLoanForgivenessAwarenessResult,
  StudentLoanNotEnrolledResult,
  StudentLoanBookAdvisorResult,
  StudentLoanBookAppointmentResult,
  StudentLoanSavingsResult,
} from 'enums/StudentLoanForgivenessFlowResults';
import { RootState } from 'handlers';

import { shouldDisplayOffer } from 'components/StudentLoanForgiveness/commonCalculations';

import { RouterType } from './FlowRouter';

export const StudentLoanForgivenessRoutes: RouterType = {
  [RoutePath.StudentLoanEmployerType]: {
    navigationInfo: { showBackLink: false, title: 'Student Loan Forgiveness' },
    component: EmployerType,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case StudentLoanEmployerResult.NonProfit:
          navigate(RoutePath.StudentLoanNonProfitHireDate);
          break;
        case StudentLoanEmployerResult.ForProfit:
          navigate(RoutePath.StudentLoanBookAdvisor);
          break;
        case StudentLoanEmployerResult.NotSure:
          navigate(RoutePath.StudentLoanForgivenessAwareness);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanNonProfitHireDate]: {
    navigationInfo: { showBackLink: true, title: 'Student Loan Forgiveness' },
    component: NonProfitHireDate,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.StudentLoanForgivenessAwareness);
    },
  },
  [RoutePath.StudentLoanForgivenessAwareness]: {
    navigationInfo: { showBackLink: true, title: 'Student Loan Forgiveness' },
    component: ForgivenessAwareness,
    handleNext: ({ state, navigate }) => (result) => {
      const displayOffer = shouldDisplayOffer(state.studentLoanData);
      switch (result) {
        case StudentLoanForgivenessAwarenessResult.Yes:
          navigate(RoutePath.StudentLoanNotEnrolled);
          break;
        case StudentLoanForgivenessAwarenessResult.No:
          navigate(displayOffer ? RoutePath.StudentLoanSavings : RoutePath.StudentLoanBookAdvisor);
          break;
        default:
          break;
      }
    },
  },
  [RoutePath.StudentLoanNotEnrolled]: {
    navigationInfo: { showBackLink: true, title: 'Student Loan Forgiveness' },
    component: NotEnrolled,
    handleNext: ({ state, navigate }) => (result) => {
      const displayOffer = shouldDisplayOffer(state.studentLoanData);
      switch (result) {
        case StudentLoanNotEnrolledResult.Applying:
          navigate(RoutePath.StudentLoanBookAdvisor);
          break;
        case StudentLoanNotEnrolledResult.WontApprove:
        case StudentLoanNotEnrolledResult.DifficultToApply:
        case StudentLoanNotEnrolledResult.WontSaveMoney:
          navigate(displayOffer ? RoutePath.StudentLoanSavings : RoutePath.StudentLoanBookAdvisor);
          break;
        default:
          navigate(RoutePath.Error);
      }
    },
  },
  [RoutePath.StudentLoanSavings]: {
    navigationInfo: { showBackLink: true, title: 'Student Loan Forgiveness' },
    component: StudentLoanSavings,
    handleNext: ({ navigate, state }) => (result) => {
      switch (result) {
        case StudentLoanSavingsResult.Next:
          navigate(RoutePath.StudentLoanBookAppointment);
          break;
        case StudentLoanSavingsResult.NotNow:
          next(state, navigate);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanBookAppointment]: {
    navigationInfo: { showBackLink: true, title: 'Student Loan Forgiveness' },
    component: BookAppointment,
    handleNext: ({ state, navigate }) => (result) => {
      switch (result) {
        case StudentLoanBookAppointmentResult.Schedule:
        case StudentLoanBookAppointmentResult.NotNow:
          next(state, navigate);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanBookAdvisor]: {
    navigationInfo: { showBackLink: true, title: 'Student Loan Forgiveness' },
    component: BookAdvisor,
    handleNext: ({ navigate, state }) => (result) => {
      switch (result) {
        case StudentLoanBookAdvisorResult.Schedule:
        case StudentLoanBookAdvisorResult.NotNow:
          next(state, navigate);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
};

// handles navigation to next step after Student Loan Forgiveness, either to Paycheck Switch or Referral Program
const next = (state: RootState, navigate: (to: RoutePath, options?: NavigateOptions) => void) => {
  if (state.paycheckSwitchData.eligible) {
    navigate(RoutePath.ConnectPaycheck);
  } else {
    navigate(RoutePath.ReferralProgram);
  }
};
