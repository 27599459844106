import React, { FC } from 'react';
import Loader from 'components/Loader';

import styles from './PageLoader.module.scss';

interface PageLoaderProps {
  title?: string;
  subtitle?: string;
}

const PageLoader: FC<PageLoaderProps> = ({ title, subtitle }) => (
  <div className={styles.container}>
    <div>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
    </div>
    <Loader color="white" />
  </div>
);

export default PageLoader;
