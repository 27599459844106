import Api, { FetchMethodType } from './Api';

export interface StudentLoanAssistanceData {
  eligible: boolean;
  paymentStartDate?: string;
  maxTerm?: number;
  totalBalance?: number;
  monthSavings?: number;
  monthsToStandardRepayment?: number;
  monthsToPublicServiceRepayment?: number;
}

export interface StudentLoanApi {
  getStudentLoanAssistanceData(applicationId: string, startDate?: string): Promise<StudentLoanAssistanceData>;
  setStudentLoanAssistanceSchedule(applicationId: string, eventUri?: string): Promise<void>;
}

export default class StudentLoanRestApi extends Api implements StudentLoanApi {
  async getStudentLoanAssistanceData(applicationId: string, startDate?: string): Promise<StudentLoanAssistanceData> {
    return this.fetch<StudentLoanAssistanceData>(
      `/studentLoan/assistanceData/${applicationId}/?paymentStartDate=${startDate || ''}`,
    );
  }

  async setStudentLoanAssistanceSchedule(applicationId: string, eventUri?: string): Promise<void> {
    return this.fetch(`/studentLoan/schedule/${applicationId}/`, { method: FetchMethodType.POST, body: { eventUri } });
  }
}
