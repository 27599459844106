import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { FlowComponentType } from 'routes/FlowRouter';
import FormContainer from 'components/LoanForm/FormContainer';
import FormNavigation from 'components/FormNavigation';
import Button from 'components/Button';
import TextArea from 'components/TextArea';
import Checkbox from 'components/Checkbox';
import { getPluralGroupTerm } from 'enums/ProfessionGroup';
import { getCardData } from 'selectors/getCardData';

import styles from './PaycheckFeedback.module.scss';

const PaycheckFeedback = ({ handleNext }: FlowComponentType) => {
  const { professionGroup } = useSelector(getCardData);

  const [feedback, setFeedback] = useState<{
    experience: string;
    reason: string;
    contact: boolean;
  }>({
    experience: '',
    reason: '',
    contact: false,
  });

  const handleReasonOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback((prevState) => ({
      ...prevState,
      reason: event.target.value,
    }));
  };

  const handleExperienceOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback((prevState) => ({
      ...prevState,
      experience: event.target.value,
    }));
  };

  const handleContactOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback((prevState) => ({
      ...prevState,
      contact: event.target.checked,
    }));
  };

  return (
    <div>
      <FormNavigation title="Feedback" />
      <FormContainer
        title="We'd love your feedback."
        subtitle={`We'd like to know how we can improve our product so we can help other ${getPluralGroupTerm(
          professionGroup!,
        )}`}
      >
        <div className={styles.descriptionContainer}>
          <p className={styles.subtitle}>Why didn't you proceed?</p>
          <TextArea
            placeholder="Tell us anything on your mind."
            className={styles.formInput}
            name="reason"
            onChange={handleReasonOnChange}
            value={feedback.reason}
          />

          <p className={styles.subtitle}>How was your experience?</p>
          <TextArea
            placeholder="Any details are welcome."
            className={styles.formInput}
            name="experience"
            onChange={handleExperienceOnChange}
            value={feedback.experience}
          />

          <Checkbox label="May we get in touch with you?" onChange={handleContactOnChange} />
        </div>
        <Button
          onClick={() => {
            analytics.track('Paycheck Switch - Not Now', feedback);
            handleNext();
          }}
        >
          Next
        </Button>
      </FormContainer>
    </div>
  );
};

export default PaycheckFeedback;
