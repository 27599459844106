import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPaycheckSwitchOffer } from 'thunks';
import { PaycheckSwitchOfferData } from 'api/PaycheckSwitchApi';

export interface PaycheckSwitchData {
  applied?: boolean;
  eligible?: boolean;
  installmentPayments?: boolean;
  interestRate?: number;
  creditLimit?: number;
}

const initialState: PaycheckSwitchData = {
  applied: false,
};

const auth = createSlice({
  name: 'paycheckSwitchData',
  initialState,
  reducers: {
    setPaycheckSwitchApplied: (state: PaycheckSwitchData, { payload }: PayloadAction<boolean>) => {
      state.applied = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPaycheckSwitchOffer.fulfilled, (state, { payload }: PayloadAction<PaycheckSwitchOfferData>) => {
      state.eligible = true;
      state.installmentPayments = payload.installmentPayments;
      state.interestRate = payload.interestRate;
      state.creditLimit = payload.creditLimit;
    });
    builder.addCase(getPaycheckSwitchOffer.rejected, (state) => {
      state.eligible = false;
    });
  },
});

export const { setPaycheckSwitchApplied } = auth.actions;

export default auth.reducer;
