import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StudentLoanAssistanceData } from 'api/StudentLoanApi';
import { getStudentLoanAssistanceData } from 'thunks';

interface StudentLoanDataFetchStatus {
  isLoading: boolean;
  error: boolean;
  fetched: boolean;
  refetch: boolean;
}
export interface StudentLoanData {
  applicationId?: string;
  eligible?: boolean;
  paymentStartDate?: string;
  maxTerm?: number;
  totalBalance?: number;
  monthSavings?: number;
  monthsToStandardRepayment?: number;
  monthsToPublicServiceRepayment?: number;
}

type StudentLoanDataState = StudentLoanData & StudentLoanDataFetchStatus;

const initialState: StudentLoanDataState = {
  isLoading: false,
  error: false,
  fetched: false,
  refetch: false,
};

const auth = createSlice({
  name: 'studentLoanData',
  initialState,
  reducers: {
    setPaymentStartDate: (state: StudentLoanData, { payload }: PayloadAction<string>) => {
      state.paymentStartDate = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentLoanAssistanceData.pending, (state, action) => {
      state.applicationId = action.meta.arg.applicationId;
      state.refetch = state.fetched;
      state.isLoading = true;
      state.fetched = false;
    });
    builder.addCase(
      getStudentLoanAssistanceData.fulfilled,
      (state, { payload }: PayloadAction<StudentLoanAssistanceData>) => {
        state.isLoading = false;
        state.fetched = true;

        state.eligible = payload.eligible;
        state.paymentStartDate = payload.paymentStartDate;
        state.maxTerm = payload.maxTerm;
        state.totalBalance = payload.totalBalance;
        state.monthSavings = payload.monthSavings;
        state.monthsToStandardRepayment = payload.monthsToStandardRepayment;
        state.monthsToPublicServiceRepayment = payload.monthsToPublicServiceRepayment;
      },
    );
    builder.addCase(getStudentLoanAssistanceData.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
      state.fetched = false;
      state.eligible = false;
    });
  },
});

export const { setPaymentStartDate } = auth.actions;

export default auth.reducer;
