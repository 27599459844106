import React, { useEffect } from 'react';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { ReactComponent as UserIcon } from 'images/profile.svg';
import Modal from 'components/Common/Modal';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import nurseJasonImage from 'images/nurse-jason.png';

import styles from './AreYouSureModal.module.scss';

type AreYouSureModalProps = {
  onClose: () => void;
  onNext: () => void;
  onNotNow: () => void;
  nextLabel?: string;
};

const colleagues = Object.freeze([
  {
    name: 'Katie K',
    savings: 131080,
  },
  {
    name: 'Beau G',
    savings: 297235,
  },
  {
    name: 'Dr. Russel F',
    savings: 647235,
  },
  {
    name: 'Whitney D',
    savings: 77240,
  },
]);

const AreYouSureModal = ({ onClose, onNext, onNotNow, nextLabel }: AreYouSureModalProps) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <Modal closeModal={onClose}>
      <>
        <p className={styles.title}>Are You Sure?</p>

        <p className={styles.label}>Join your colleagues who have gotten life changing savings.</p>

        <div className={styles.colleagues}>
          {colleagues.map((colleague) => (
            <div key={colleague.name.trim()} className={styles.colleagueRow}>
              <UserIcon className={styles.userIcon} />
              <p className={styles.colleagueName}>{colleague.name}</p>
              <p className={styles.colleagueSavings}>{formatMonetaryAmount(colleague.savings)}</p>
            </div>
          ))}
        </div>

        <div className={styles.testimonial}>
          <img className={styles.image} src={nurseJasonImage} alt="Nurse Jason" />
          <p>
            Plannery has been life changing. I’m so thankful to have a plan to be debt free in 3 years. <br />{' '}
            <strong>- Jason</strong>, PA-C
          </p>
        </div>

        <Button className={styles.button} onClick={onNext}>
          {nextLabel || 'Next'}
        </Button>

        <Button type={ButtonType.Inverted} onClick={onNotNow}>
          Not Now
        </Button>
      </>
    </Modal>
  );
};

export default AreYouSureModal;
